
$(document).ready(function() {

	const newObj = [{name: "hello"},{name: "hello1"},{name: "hello2"},{name: "hello3"}]

	const test = newObj.map(item => item)

	console.log(newObj.length);

	for(var i= 0; i++; i > newObj.length){
		console.log(i, this);
		const div = document.createElement('div');
		// div.innerHTML
	}
	
	// forEach doesn return an array
	const test1 = newObj.forEach(item=> document.createElement('div'));

	console.log(test,'hello',test1,'h')

	$('body').scrollTop(0);

	$('body').addClass('loaded');
	setTimeout(() => {
		$('body').addClass('animate');
	}, 2500);
	var controller = new ScrollMagic.Controller();
	var tl = new TimelineMax();
	tl.set(".sunshine", {
		translateX: '-50%',
		roatation: 0,
		xPercent: -50,
		transformOrigin:"50% 50%"         // make transform origin be center for x and y axis
	});
	tl.fromTo('.sunshine', 1, {rotation: 360, xPercent: -50},{rotation: 0,xPercent: -50})
	var scene1 = new ScrollMagic.Scene({
						triggerElement: ".wrapper",
						offset: 500,
						triggerElement: 0.1,
						duration: "2000%"
					})
					.setTween("body", 1, {backgroundColor: "#3f3f3f",  ease: Linear.easeIn,autoRound:false}) // 
					// .setTween($(".sunshine"), 1, {rotation: 360, ease: Power1.easeOut}) // trigger a TweenMax.to tween
					.addTo(controller);

	var scene1 = new ScrollMagic.Scene({
						triggerElement: ".wrapper",
						offset: 500,
						triggerElement: 0.1,
						duration: "1000%"
					})
					// .setTween("body", 1, {backgroundColor: "#e2d7cf",  ease: Power1.easeOut}) // 
					.setTween($(".sunshine"), 1, {rotation: -360, ease: Linear.easeIn}) // trigger a TweenMax.to tween
					.addTo(controller);
	// $(".sunshine").each(function() {
	// 	var tl = new TimelineMax();
	// 	// var child = $(this).find("img");
	// 	tl.to($(this), 1, { rotation: 360, opacity:1, ease: Linear.easeIn ,autoRound:false, force3D:true});
	// 	var scene = new ScrollMagic.Scene({
	// 		triggerElement: this,
	// 		triggerHook: 1,
	// 		duration: "100%",
	// 	})
	// 		.setTween(tl)
	// 		// .setClassToggle( this, 'animate')
	// 		// .reverse(false)
	// 		.addTo(controller);
	// });
	$(".project-images .image").each(function() {
		var tl = new TimelineMax();
		var child = $(this).find("img");
		tl.to($(this), 1, { x: 0, opacity:1, ease: Linear.easeIn ,autoRound:false, force3D:true});
		var scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 1,
			duration: "100%",
		})
			.setTween(tl)
			// .setClassToggle( this, 'animate')
			// .reverse(false)
			.addTo(controller);
	});


	$(".side-img").each(function() {
		var tl = new TimelineMax();
		var child = $(this).find("img");
		tl.to($(this), 1, { y: -100, ease: Linear.easeIn ,autoRound:false, force3D:true});
		var scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 1,
			duration: "80%",
			offset: 50
		})
			.setTween(tl)
			.addTo(controller);
	});

	$(".fast").each(function() {
		var tl = new TimelineMax();
		var child = $(this).find("img");
		tl.to($(this), 1, { y: -200, ease: Power1.easeOut ,autoRound:false, force3D:true});
		var scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 1,
			duration: "100%"
		})
			.setTween(tl)
			.addTo(controller);
	});


	$(".faster").each(function() {
		var tl = new TimelineMax();
		var child = $(this).find("img");
		tl.to($(this), 1, { y: -300, ease: Power1.easeIn ,autoRound:false, force3D:true});
		var scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 1,
			duration: "100%"
		})
			.setTween(tl)
			.addTo(controller);
	});
	$(".slow").each(function() {
		var tl = new TimelineMax();
		var child = $(this).find("img");
		tl.to($(this), 1, { y: -60, ease: Linear.ease ,autoRound:false, force3D:true});
		var scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 0.6,
			duration: "100%"
		})
			.setTween(tl)
			.addTo(controller);
	});

})
